import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-save-format-dialog',
  templateUrl: './save-format-dialog.component.html',
  styleUrls: ['./save-format-dialog.component.css']
})
export class SaveFormatDialogComponent {

  constructor(private bottomSheetRef: MatBottomSheetRef<any>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { 
    console.log(data);
  }

  chooseFormat(option: number) {
    this.data.option = option;
    this.bottomSheetRef.dismiss(this.data);
  }

}
