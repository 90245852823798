import { Component, OnInit } from '@angular/core';
import { containsElement } from '@angular/animations/browser/src/render/shared';


@Component({
  selector: 'app-help',
  // template: '<p *isPortrait>l show you if Im a portrait state.</p><p *isLandscape>I show you if Im a landscape state.</p>',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
  menu;
  constructor() {  }

  ngOnInit() {
    this.menu = '';
  }

}
