import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DefaultsService {
  /* Test */
  isTest = true;

  /* System */
  title = 'GameStats';
  version = '0.3 Beta';
  reloadInterval = 3;

  /* Game data */
  nameHomeTeam = 'Home Team';
  nameAwayTeam = 'Away Team';
  numHalfs = 2;
  oneHalfDuration = 30;
  inactivityTimeout = 10;
  passString = 3;
  runsAfterGoal = false;

  /* Cookie data */
  cookieName = 'gamestatsgame';
  tournoCookieName = 'tournoStarted';
  readTournoCookie = true;
  cookieExpiryDays = 1;
  cookieUpdateInterval = 5;
  cookieUpdateIntervalDefault = 60;
}
