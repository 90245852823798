import { DefaultsService } from './../defaults.service';
import { GameService } from './../game.service';
import { Component, OnInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';

import { Router } from '@angular/router';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { LogService } from './../log.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnChanges, OnDestroy {

  /* NEW AREA START */

  notify: string; // used by ngx-countdown
  gameStarted: number;
  gameStartedAfterReload: boolean;
  countDownConfig: {};
  countDownNotify: number[];

  faChartLine = faChartLine;
  possessionTeam: number;
  resetExecuted: boolean;
  halfResetExecuted: boolean;
  countdowntimerStarted: boolean;

  /* TEAM 1 */
  numTeam1PossessionTimerRunning: boolean;
  team1TimerRef: number;

  /* TEAM 2 */
  numTeam2PossessionTimerRunning: boolean;
  team2TimerRef: number;

  /* NEW AREA END */
  orientationHintTaken: boolean;
  isLandscape: boolean;

  // tslint:disable-next-line:max-line-length
  constructor(
    private router: Router,
    private defaultsService: DefaultsService,
    public gameService: GameService,
    private logger: LogService
    ) {
      this.manageInitialOrientation();
    }

    manageInitialOrientation() {
      // Find matches
      let mql = window.matchMedia('(orientation: portrait)');

      // If there are matches, we're in portrait
      if (mql.matches) { // Portrait orientation
        this.isLandscape = false;
        this.orientationHintTaken = false;
      } else {
        this.orientationHintTaken = true;
        this.isLandscape = true;
      }

      // Add a media query change listener
      if (!this.isLandscape) {
        mql.addListener((m) => {
          if (m.matches) {
            this.isLandscape = false;
          } else {
            this.orientationHintTaken = true;
            this.isLandscape = true;
          }
        });
      }
    }


  /* INITIALISATION - START */

  ngOnInit() {
    // this.logger.log(this.gameService.game.gameData);
    // this.countdownValue = this.gameService.game.gameData.countdownValue;
    this.possessionTeam = 0;
    this.resetExecuted = false;
    this.halfResetExecuted = false;
    this.countdowntimerStarted = false;

    /* TEAM 1 */
    this.numTeam1PossessionTimerRunning = false;

    /* TEAM 2 */
    this.numTeam2PossessionTimerRunning = false;

    setInterval(() => { if (this.gameStarted === 1 && (this.gameService.game.gameData.gameStatus === 11 || this.gameService.game.gameData.gameStatus === 21)) {this.updateAll(); } }, this.defaultsService.reloadInterval * 1000);
    /* setInterval(() => { this.updateGameCookie(); }, this.defaultsService.cookieUpdateIntervalDefault * 1000); */

    /* game (:Game) has been injected - the constructor of this object will look for a cookie and load data from that */
    /* If no cookie is found, the abject initialises content, sets a cookie and retruns that content */

    this.initialiseTimer(this.gameService.game.gameData.gameStatus === 10 || this.gameService.game.gameData.gameStatus === 20 ? this.gameService.game.gameData.countdownValueOriginalSeconds : 0);


    if (this.gameService.game.gameData.gameStatus > 10 &&  this.gameService.game.gameData.gameStatus !== 13 && this.gameService.game.gameData.gameStatus !== 23) {
      this.gameStartedAfterReload = true;
      this.gameStarted = 2;
    } else {
      this.gameStarted = 0;
    }
    this.countdowntimerStarted = this.gameService.game.gameData.gameStatus === 12 || this.gameService.game.gameData.gameStatus === 22 ? true : false;
  }

  initialiseTimer(secondsToUse: number = 0) {
    this.countDownNotify = [];
    // alert('secondsToUse = ' + secondsToUse);
    if (secondsToUse && this.gameService.cdownValue < secondsToUse) {this.gameService.cdownValue = secondsToUse; }
    // alert('this.gameService.cdownValue = ' + this.gameService.cdownValue);
    let counter = this.gameService.cdownValue;
    // this.logger.log(counter);
    while (counter > 0) {
      counter = counter - this.gameService.game.gameData.updateInterval;
      if (counter > 0) {
        this.countDownNotify.push(counter);
      }
    }

    this.gameStartedAfterReload = false;

    this.countDownConfig = {leftTime: this.gameService.cdownValue, notify: this.countDownNotify};
  }

  /* INITIALISATION - END */



 handleEvent(e: any) {
    this.notify = e.action.toUpperCase();
    if (e.action === 'notify') {
      this.notify += ` - ${e.left} ms`;
    }

    /*
      On each 'notify', we could update the cookie with the 'left' value
      We also get an 'action' which means we could reset the gameStatus as necessary
      {action: "notify", left: 2000} - update countdownValue with 'left' vale
      {action: "finished", left: 0} - update countdownValue with 'left' value and update gameSataus with either firsthalf ended or game ended
    */

    this.gameService.game.gameData.countdownValue = e.left;

    if (e.action === 'finished') {
      // this.gameService.game.gameData.gameStatus = this.gameService.game.gameData.half === 1 ? 13 : 23;
      this.gameService.game.gameData.countdownValue = this.gameService.game.gameData.countdownValueOriginalSeconds;
    }
    if (e.left !== this.gameService.game.gameData.countdownValueOriginalSeconds * 1000) {
    this.gameService.game.updateGameCookie();
    this.logger.log(e);
    }
  }

  updateTeamNames() {
    // the cookie is updated immediatelly
    this.gameService.updateTeamNames();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  startGame() {
    this.gameStarted = 1;
    this.countdowntimerStarted = true;
    this.initialiseTimer(this.gameService.game.gameData.gameStatus === 10 || this.gameService.game.gameData.gameStatus === 20 ? this.gameService.game.gameData.countdownValueOriginalSeconds : 0);
    this.gameService.game.gameData.gameStatus = this.gameService.game.gameData.half === 1 ? 11 : 21;
    this.gameService.setTimers(this.gameService.game.gameData.half);

    this.gameStartedAfterReload = false;
    this.gameService.updateCookie();
  }

  pauseGame() {
    this.gameService.game.gameData.gameStatus = this.gameService.game.gameData.half === 1 ? 12 : 22;
    this.gameStarted = 2;
    this.possessionTeam = 0;
    clearInterval(this.team1TimerRef);
    clearInterval(this.team2TimerRef);
    this.updateAll();
    this.gameService.updateCookie();
  }

  resumeGame() {
    this.gameService.game.gameData.gameStatus = this.gameService.game.gameData.half === 1 ? 11 : 21;
    this.gameStarted = 1;
    this.gameService.updateCookie();
  }

  endGame() {
    this.logger.log(this.gameService.game.gameData);
    this.possessionTeam === 1 ? this.gameService.passesDetailsTeam1.push(this.gameService.passStringsCounter) : this.gameService.passesDetailsTeam2.push(this.gameService.passStringsCounter);
    this.possessionTeam = 0;
    this.gameStarted = 0;

    if (this.gameService.game.gameData.settings.numHalfs === 1) {
      this.gameService.game.gameData.gameStatus = 13;
      this.updateAll();
      // this.gameService.buildFirstHalf();
      // this.gameService.buildSecondHalf();
      // this.gameService.buildSummary();
      this.resetCounters();
      this.gameService.updateCookie();
      this.goToSummary();
    }

    if (this.gameService.game.gameData.half === 2) {
      this.gameService.game.gameData.gameStatus = 23;
      this.updateAll();
        // this.gameService.buildSecondHalf();
        // this.gameService.buildSummary();
      // clearInterval(this.team1TimerRef); clearInterval(this.team2TimerRef);
      this.resetCounters();
      this.gameService.updateCookie();
      this.goToSummary();
    }


    if (this.gameService.game.gameData.half === 1 && (this.gameService.game.gameData.gameStatus === 11 || this.gameService.game.gameData.gameStatus === 12) && this.gameService.game.gameData.settings.numHalfs > 1) {
        this.gameService.game.gameData.half = 2;
        this.gameService.game.gameData.gameStatus = 20;
        this.gameService.updateCookie();
        this.updateAll();
        // this.gameService.buildFirstHalf();
        this.resetHalfCounters();
    }
  }

  updateAll() {
    this.logger.log('updateAll() ......');
    // this.logger.log(this.gameService);
    this.gameService.possessionPercentageTeam1 = this.gameService.possessionTimeMillisecondsTeam2 !== 0 ? Math.floor(this.gameService.possessionTimeMillisecondsTeam1 / (this.gameService.possessionTimeMillisecondsTeam1 + this.gameService.possessionTimeMillisecondsTeam2) * 100) : 0;
    this.gameService.possessionPercentageTeam2 = this.gameService.possessionTimeMillisecondsTeam1 !== 0 ? Math.floor(this.gameService.possessionTimeMillisecondsTeam2 / (this.gameService.possessionTimeMillisecondsTeam1 + this.gameService.possessionTimeMillisecondsTeam2) * 100) : 0;

    if (this.gameService.possessionTimeMillisecondsTeam1 > 0 && this.gameService.possessionTimeMillisecondsTeam2 === 0) { this.gameService.possessionPercentageTeam1 = 100; }
    if (this.gameService.possessionTimeMillisecondsTeam2 > 0 && this.gameService.possessionTimeMillisecondsTeam1 === 0) { this.gameService.possessionPercentageTeam2 = 100; }

    if (this.gameService.possessionPercentageTeam1 + this.gameService.possessionPercentageTeam2 !== 100) {
      if (this.gameService.possessionPercentageTeam1 > this.gameService.possessionPercentageTeam2) {
        this.gameService.possessionPercentageTeam1 = this.gameService.possessionPercentageTeam1 + 1;
      } else {
        this.gameService.possessionPercentageTeam2 = this.gameService.possessionPercentageTeam2 + 1;
      }
    }

    this.gameService.updateTeamPossessionPercentage();
    // this.logger.log(this.game.gameData);
  }

  goToSummary() {
    this.gameService.updateTotalData();
    this.gameService.firstTouchCountTeam1 = this.gameService.passCountTeam1 = this.gameService.passCompletionPercentageTeam1 = this.gameService.possessionTimeMillisecondsTeam1 = this.gameService.possessionPercentageTeam1 = this.gameService.passStringsTeam1 = 0;
    this.gameService.firstTouchCountTeam2 = this.gameService.passCountTeam2 = this.gameService.passCompletionPercentageTeam2 = this.gameService.possessionTimeMillisecondsTeam2 = this.gameService.possessionPercentageTeam2 = this.gameService.passStringsTeam2 = 0;
    this.router.navigateByUrl('/summary');
  }

  stopPosessionTimers() {
    if (!this.gameService.game.gameData.settings.runsAfterGoal) {
      this.logger.log('this.gameService.game.gameData.settings.runsAfterGoal = ' + this.gameService.game.gameData.settings.runsAfterGoal);
      clearInterval(this.team1TimerRef);
      clearInterval(this.team2TimerRef);
      this.possessionTeam = 0;
      this.logger.log('this.possessionTeam = ' + this.possessionTeam);
      this.numTeam1PossessionTimerRunning = this.numTeam2PossessionTimerRunning = false;
      this.gameService.passStringsCounter = 0;
    }
  }


  plusTeam1Goals() {
    this.checkGameStatus();
    if (this.gameStarted === 1) {
      this.possessionTeam = 1;
      this.gameService.numHalfGoalsTeam1++;
      this.gameService.numGoalsTeam1++;
      this.gameService.addTeamGoalDetails(1);
      this.plusTeam1Shots();
      this.gameService.updateGoalShotCount(1);
      this.stopPosessionTimers();
    }
  }
  minusTeam1Goals() {
    this.checkGameStatus();
    if (this.gameStarted === 1 ) {
      if (this.gameService.numGoalsTeam1 > 0) {
        this.gameService.numHalfGoalsTeam1--;
        this.gameService.numGoalsTeam1--;
        if (this.gameService.firstTouchCountTeam1 > 0) { this.gameService.firstTouchCountTeam1--; }
        if (this.gameService.passCountTeam1 > 0) { this.gameService.passCountTeam1--; }
        this.gameService.minusTeamGoalDetails(1);
        this.minusTeam1Shots();
        this.gameService.updateGoalShotCount(1);
      }
    }
  }

  plusTeam2Goals() {
    this.checkGameStatus();
    if (this.gameStarted === 1 ) {
      this.possessionTeam = 2;
      this.gameService.numHalfGoalsTeam2++;
      this.gameService.numGoalsTeam2++;
      this.gameService.addTeamGoalDetails(2);
      this.plusTeam2Shots();
      this.gameService.updateGoalShotCount(2);
      this.stopPosessionTimers();
    }
  }
  minusTeam2Goals() {
    this.checkGameStatus();
    if (this.gameStarted === 1 ) {
      if (this.gameService.numGoalsTeam2 > 0) {
        this.gameService.numHalfGoalsTeam2--;
        this.gameService.numGoalsTeam2--;
        if (this.gameService.firstTouchCountTeam2 > 0) { this.gameService.firstTouchCountTeam2--; }
        if (this.gameService.passCountTeam2 > 0) { this.gameService.passCountTeam2--; }
        this.gameService.minusTeamGoalDetails(2);
        this.minusTeam2Shots();
        this.gameService.updateGoalShotCount(2);
      }
    }
  }

  plusTeam1Shots() {
    this.checkGameStatus();
    if (this.gameStarted === 1 ) {
      if (this.possessionTeam !== 1) { this.gameService.passStringsCounter = 0; }
      this.possessionTeam = 1;
      if (!this.numTeam1PossessionTimerRunning) { this.possessionTimer(1); }
      this.gameService.numShotsTeam1++;
      this.gameService.firstTouchCountTeam1++;
      this.gameService.passCountTeam1++;
      this.gameService.updateGoalShotCount(1);
    }
  }
  minusTeam1Shots() {
    this.checkGameStatus();
    if (this.gameStarted === 1 ) {
      if (this.gameService.numShotsTeam1 > 0 && this.gameService.numShotsTeam1 > this.gameService.numGoalsTeam1) {
        this.gameService.numShotsTeam1--;
        // this.gameService.passCountToGoalTeam1--;
      }
    }
  }

  plusTeam2Shots() {
    this.checkGameStatus();
    if (this.gameStarted === 1 ) {
      if (this.possessionTeam !== 2) { this.gameService.passStringsCounter = 0; }
      this.possessionTeam = 2;
      if (!this.numTeam2PossessionTimerRunning) { this.possessionTimer(2); }
      this.gameService.numShotsTeam2++;
      this.gameService.firstTouchCountTeam2++;
      this.gameService.passCountTeam2++;
      this.gameService.updateGoalShotCount(2);
    }
  }
  minusTeam2Shots() {
    this.checkGameStatus();
    if (this.gameStarted === 1 ) {
      if (this.gameService.numShotsTeam2 > 0 && this.gameService.numShotsTeam2 > this.gameService.numGoalsTeam2) {
        this.gameService.numShotsTeam2--;
        // this.gameService.passCountToGoalTeam2--;
      }
    }
  }

  checkGameStatus() {
    this.logger.log('this.gameStarted = ' + this.gameStarted);
    this.logger.log('this.countdowntimerStarted = ' + this.countdowntimerStarted);
    if (this.gameStarted === 0 && !this.countdowntimerStarted) {alert('Game is not started!'); /*if (confirm("Start the game?") === true) {}*/}
    if (this.gameStarted === 2 && this.countdowntimerStarted) {alert('Game is Paused!'); /*if (confirm("Start the game?") === true) {}*/}
  }

  recalculateAll() {
    // tslint:disable-next-line:max-line-length
    this.gameService.passCompletionPercentageTeam1 = this.gameService.firstTouchCountTeam1 ? Math.floor(this.gameService.passCountTeam1 / this.gameService.firstTouchCountTeam1 * 100) : 0;
    // tslint:disable-next-line:max-line-length
    this.gameService.passCompletionPercentageTeam2 = this.gameService.firstTouchCountTeam2 ? Math.floor(this.gameService.passCountTeam2 / this.gameService.firstTouchCountTeam2 * 100) : 0;

    this.gameService.updateTeamPassPercentage();
  }


  plusTeamTouch(team) {
    this.checkGameStatus();
    if (this.gameStarted === 1) {
      if (this.possessionTeam !== team) {
        this.possessionTimer(team);

        // update pass array for other team
        if (team === 1) { /* team 1 touch */
          /* reset the consecutive pass count for team 2 */
          this.gameService.passCountToGoalTeam2 = 0;
          this.logger.log('do something with team 2');
          // other team === 2
          if (this.gameService.passStringsCounter) {
            this.logger.log('this.passStringsCounter = ' + this.gameService.passStringsCounter);
            this.gameService.passesDetailsTeam2.push(this.gameService.passStringsCounter);
            this.logger.log(this.gameService.passesDetailsTeam2);
          }
        } else {
          /* team 2 toureset the consecutive pass count for team 1 */
          this.gameService.passCountToGoalTeam1 = 0;
          this.logger.log('do something with team 1');
          // other team === 1
          if (this.gameService.passStringsCounter) {
            this.logger.log('this.passStringsCounter = ' + this.gameService.passStringsCounter);
            this.gameService.passesDetailsTeam1.push(this.gameService.passStringsCounter);
            this.logger.log(this.gameService.passesDetailsTeam1);

          }
        }
      }
      if (this.possessionTeam === team) {
        team === 1 ? this.gameService.passCountTeam1++ : this.gameService.passCountTeam2++;
        team === 1 ? this.gameService.passCountToGoalTeam1++ : this.gameService.passCountToGoalTeam2++;
        this.gameService.passStringsCounter++;
        this.logger.log('this.passStringsCounter = ' + this.gameService.passStringsCounter);
        this.logger.log('this.passString = ' + this.gameService.game.gameData.settings.passString);

        if (this.gameService.passStringsCounter === this.gameService.game.gameData.settings.passString) {
          team === 1 ? this.gameService.passStringsTeam1++ : this.gameService.passStringsTeam2++;
        }
      } else {
        this.gameService.passStringsCounter = 0;
      }
      this.possessionTeam = team;
      team === 1 ? this.gameService.firstTouchCountTeam1++ : this.gameService.firstTouchCountTeam2++;
      // team === 1 ? this.gameService.firstTouchCountTeam1++ : this.gameService.firstTouchCountTeam2++;
    }

    this.recalculateAll();
    this.gameService.updateFirstTouchPassCount(team);
    this.gameService.updateGame();
  }

  possessionTimer(team) {
    this.logger.log('possessionTimer for ' + team);
    if (team === 1) {
      this.numTeam2PossessionTimerRunning = false;
      this.numTeam1PossessionTimerRunning = true;
      clearInterval(this.team2TimerRef);

      if (this.numTeam1PossessionTimerRunning) {
        const startTime = Date.now() - (this.gameService.possessionTimeMillisecondsTeam1 || 0);
        this.team1TimerRef = setInterval(() => {
          this.gameService.possessionTimeMillisecondsTeam1 = Date.now() - startTime;

          // tslint:disable-next-line:no-bitwise
          this.gameService.possessionTimeMinutesTeam1 = Math.floor((this.gameService.possessionTimeMillisecondsTeam1 / 1000 / 60) << 0),
          this.gameService.possessionTimeSecondsTeam1 = Math.floor((this.gameService.possessionTimeMillisecondsTeam1 / 1000) % 60);

          this.gameService.possessionTimeStringTeam1 = (this.gameService.possessionTimeMinutesTeam1 === 0 || this.gameService.possessionTimeMinutesTeam1 < 10 ? '0' + this.gameService.possessionTimeMinutesTeam1 : this.gameService.possessionTimeMinutesTeam1) + ':' + (this.gameService.possessionTimeSecondsTeam1 === 0 || this.gameService.possessionTimeSecondsTeam1 < 10 ? '0' + this.gameService.possessionTimeSecondsTeam1 : this.gameService.possessionTimeSecondsTeam1);

        });
      }
    }

    if (team === 2) {
      this.numTeam1PossessionTimerRunning = false;
      this.numTeam2PossessionTimerRunning = true;
      clearInterval(this.team1TimerRef);

      if (this.numTeam2PossessionTimerRunning) {
        const startTime = Date.now() - (this.gameService.possessionTimeMillisecondsTeam2 || 0);
        this.team2TimerRef = setInterval(() => {
          this.gameService.possessionTimeMillisecondsTeam2 = Date.now() - startTime;

          // tslint:disable-next-line:no-bitwise
          this.gameService.possessionTimeMinutesTeam2 = Math.floor((this.gameService.possessionTimeMillisecondsTeam2 / 1000 / 60) << 0),
          this.gameService.possessionTimeSecondsTeam2 = Math.floor((this.gameService.possessionTimeMillisecondsTeam2 / 1000) % 60);

          this.gameService.possessionTimeStringTeam2 = (this.gameService.possessionTimeMinutesTeam2 === 0 || this.gameService.possessionTimeMinutesTeam2 < 10 ? '0' + this.gameService.possessionTimeMinutesTeam2 : this.gameService.possessionTimeMinutesTeam2) + ':' + (this.gameService.possessionTimeSecondsTeam2 === 0 || this.gameService.possessionTimeSecondsTeam2 < 10 ? '0' + this.gameService.possessionTimeSecondsTeam2 : this.gameService.possessionTimeSecondsTeam2);

        });
      }
    }
  }

  resetHalfCounters() {
    this.numTeam1PossessionTimerRunning = this.numTeam2PossessionTimerRunning = false;
    clearInterval(this.team1TimerRef); clearInterval(this.team2TimerRef);
    this.countdowntimerStarted = false;
    this.possessionTeam = 0;
    this.halfResetExecuted = true;
    this.gameService.numHalfGoalsTeam1 = this.gameService.numHalfGoalsTeam2 = 0;
    this.gameService.numShotsTeam1 = this.gameService.numShotsTeam2 = 0;

    this.gameService.possessionTimeMinutesTeam1 = this.gameService.possessionTimeMinutesTeam2 = 0;
    this.gameService.possessionTimeSecondsTeam1 = this.gameService.possessionTimeSecondsTeam2 = 0;
    this.gameService.possessionTimeStringTeam1 = this.gameService.possessionTimeStringTeam2 = '00:00';
    this.gameService.possessionTimerRunningTeam1 = this.gameService.possessionTimerRunningTeam2 = false;

    this.gameService.passStringsCounter = 0;

    this.gameService.firstTouchCountTeam1 = this.gameService.firstTouchCountTeam2 = 0;
    this.gameService.passCountTeam1 = this.gameService.passCountTeam2 = 0;
    this.gameService.passCompletionPercentageTeam1 = this.gameService.passCompletionPercentageTeam2 = 0;
    this.gameService.possessionTimeMillisecondsTeam1 = this.gameService.possessionTimeMillisecondsTeam2 = 0;
    this.gameService.possessionPercentageTeam1 = this.gameService.possessionPercentageTeam2 = 0;
    this.gameService.passesDetailsTeam1 = [];
    this.gameService.passesDetailsTeam2 = [];

    this.gameService.passStringsTeam1 = this.gameService.passStringsTeam2 = 0;
    this.gameService.passCountToGoalTeam1 = this.gameService.passCountToGoalTeam2 = 0;
  }

  resetGame() {
    if (confirm('Are you sure? ALL counters will be reset, you will start again!') === true) {
      this.resetCounters();
      this.gameService.resetGame();
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['/']);
    }
  }


  resetCounters() {
      // this.gameService.resetHalfCounters();
      // this.gameService.resetGoalsAndPassesDetails();
      this.gameStarted = 0;
      this.resetHalfCounters();
      this.gameService.nullTimers();
      this.gameService.numGoalsTeam1 = this.gameService.numGoalsTeam2 = 0;
      this.resetExecuted = true;
      this.countdowntimerStarted = false;
      this.gameService.updateCookie();
  }

  ngOnDestroy() {
    console.log('Destroying.....');
    this.gameService.cdownValue = this.gameService.game.gameData.countdownValue / 1000;
  }

}


