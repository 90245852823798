import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class GamecookieService {

  name: string;
  content: string;
  duration: number;

  constructor(private cookie: CookieService) { }

  createCookie(name: string, content: string, expiry: number) {
    /*console.log(content);*/
    this.cookie.set(name, content, expiry);
  }

  retrieveCookie(name: string) {
      const cookie = this.cookie.get(name);
      return cookie ? cookie : null;
  }

  checkForCookie(name: string) {
    const cookie = this.cookie.get(name);
  }

  updateCookie(name: string, content: string, expiry: number) {
    this.cookie.set(name, content, expiry);
  }

  deleteCookie(name: string) {
    this.cookie.delete(name);
  }
}
