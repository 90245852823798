import { Injectable } from '@angular/core';
import { GamecookieService } from './gamecookie.service';
import { DefaultsService } from './defaults.service';
import { GamedataInterface } from './gamedata.interface';

@Injectable({
    providedIn: 'root'
})

export class Game {
    exists: boolean;
    expiry: number;
    gameData: GamedataInterface;
    cookieUpdateCounter: number;

    constructor(private defaultService: DefaultsService, private gameCookieService: GamecookieService) {
        this.exists = false;
        this.cookieUpdateCounter = 0;
        this.initialiseGameData();
    }

    replaceText( str: string ) {
      str = str.replace('&ouml;', 'ö');
      str = str.replace('&aring;', 'å');
      str = str.replace('&auml;', 'ä');
      str = str.replace('&Ouml;', 'Ö');
      str = str.replace('&Aring;', 'Å');
      str = str.replace('&Auml;', 'Ä');
      str = str.replace('+', ' ');
      return str;
    }

    initialiseGameData(
        reinitialise: boolean = false,
        numHalfs: number = 0,
        oneHalfDuration: number = 0,
        inactivityTimeout: number = 0,
        passString: number = 0,
        runsAfterGoal: boolean = false,
        countdownValueOriginalSeconds: number = 0,
        countdownValue: number = 0,
        nameHomeTeam: string = '',
        nameAwayTeam: string = '',
        tournament: string = '',
        competition: string = '',
        game: string = '',
        fieldName: string = '',
        fieldType: string = ''
        ) {

          inactivityTimeout = this.defaultService.inactivityTimeout;
          passString = this.defaultService.passString;
          runsAfterGoal = this.defaultService.runsAfterGoal;

        /* 0 - check to see if there is a cookie from tourno */
          const tournoGameData = this.gameCookieService.retrieveCookie(this.defaultService.tournoCookieName);
          if ( tournoGameData ) {
          const tournoGame = JSON.parse(tournoGameData);
          reinitialise = true;
          numHalfs = Number(tournoGame.numHalfs);
          oneHalfDuration = tournoGame.halfDuration;
          countdownValueOriginalSeconds = oneHalfDuration * 60;
          nameHomeTeam = this.replaceText(tournoGame.homeTeam);
          nameAwayTeam = this.replaceText(tournoGame.awayTeam);
          tournament = this.replaceText(tournoGame.tournament);
          competition = this.replaceText(tournoGame.tourno);
          game = this.replaceText(tournoGame.gameName);
          fieldName = this.replaceText(tournoGame.fieldName);
          fieldType = this.replaceText(tournoGame.fieldTypeName);
        }

        /* 1 - check to see if there is a cookie stored */

          const gameDataCookieString = !reinitialise ? this.gameCookieService.retrieveCookie(this.defaultService.cookieName) : null;

          if (gameDataCookieString) {
            this.gameData = JSON.parse(gameDataCookieString);
        } else {
            /* no cookie available --> initialise gameData */

            this.gameData = {
                gameStatus: 10,
                half: 1,
                countdownValueOriginalSeconds: reinitialise ? countdownValueOriginalSeconds : this.defaultService.oneHalfDuration  * 60,
                countdownValue: reinitialise ? countdownValueOriginalSeconds : this.defaultService.oneHalfDuration  * 60,
                updateInterval: this.defaultService.cookieUpdateInterval,
                startDate: '',
                firstHalfStartDateMilliseconds: 0,
                secondHalfStartDateMilliseconds: 0,
                EndDate: '',
                EndDateMilliseconds: 0,
                fileSaveName: '',
                control: {
                    nameHomeTeam: reinitialise ? nameHomeTeam : this.defaultService.nameHomeTeam,
                    nameHomeTeamClub: '',
                    formationHomeTeam: '',
                    nameAwayTeam: reinitialise ? nameAwayTeam : this.defaultService.nameAwayTeam,
                    nameAwayTeamClub: '',
                    formationAwayTeam: '',
                    numGoalsTeam1: 0,
                    numGoalsTeam2: 0,
                    passCountToGoalTeam1: 0,
                    passCountToGoalTeam2: 0,
                    goalsDetails: []
                },
                tourno: {
                    tournament,
                    competition,
                    game,
                    fieldName,
                    fieldType,
                },
                settings: {
                    numHalfs: reinitialise ? numHalfs : this.defaultService.numHalfs,
                    oneHalfDuration: reinitialise ? oneHalfDuration : this.defaultService.oneHalfDuration,
                    inactivityTimeout: reinitialise ? inactivityTimeout : this.defaultService.inactivityTimeout,
                    passString: reinitialise ? passString : this.defaultService.passString,
                    runsAfterGoal: reinitialise ? runsAfterGoal : this.defaultService.runsAfterGoal,
                    softwareName: this.defaultService.title,
                    softwareVersion: this.defaultService.version
                },
                firstHalf: {
                    homeTeam: {
                        numGoals: 0,
                        numShots: 0,
                        countFirstTouch: 0,
                        countPass: 0,
                        percentagePassCompletion: 0,
                        millisecondsPossession: 0,
                        percentagePossession: 0,
                        countPassStrings: 0,
                        passesDetails: []
                    },
                    awayTeam: {
                        numGoals: 0,
                        numShots: 0,
                        countFirstTouch: 0,
                        countPass: 0,
                        percentagePassCompletion: 0,
                        millisecondsPossession: 0,
                        percentagePossession: 0,
                        countPassStrings: 0,
                        passesDetails: []
                    }
                },
                secondHalf: {
                    homeTeam: {
                        numGoals: 0,
                        numShots: 0,
                        countFirstTouch: 0,
                        countPass: 0,
                        percentagePassCompletion: 0,
                        millisecondsPossession: 0,
                        percentagePossession: 0,
                        countPassStrings: 0,
                        passesDetails: []
                    },
                    awayTeam: {
                        numGoals: 0,
                        numShots: 0,
                        countFirstTouch: 0,
                        countPass: 0,
                        percentagePassCompletion: 0,
                        millisecondsPossession: 0,
                        percentagePossession: 0,
                        countPassStrings: 0,
                        passesDetails: []
                    }
                },
                total: {
                    homeTeam: {
                        numGoals: 0,
                        numShots: 0,
                        countFirstTouch: 0,
                        countPass: 0,
                        percentagePassCompletion: 0,
                        millisecondsPossession: 0,
                        percentagePossession: 0,
                        countPassStrings: 0,
                        passesDetails: []
                    },
                    awayTeam: {
                        numGoals: 0,
                        numShots: 0,
                        countFirstTouch: 0,
                        countPass: 0,
                        percentagePassCompletion: 0,
                        millisecondsPossession: 0,
                        percentagePossession: 0,
                        countPassStrings: 0,
                        passesDetails: []
                    }
                }
            };

            // tslint:disable-next-line:max-line-length
            this.gameCookieService.createCookie(this.defaultService.cookieName, JSON.stringify(this.gameData), this.defaultService.cookieExpiryDays);
        }

          console.log(this.gameData);

    }

    updateGameCookie() {
        // tslint:disable-next-line:max-line-length
        console.log(this.gameData);
        this.gameCookieService.updateCookie(this.defaultService.cookieName, JSON.stringify(this.gameData), this.defaultService.cookieExpiryDays);
        this.cookieUpdateCounter++;

    }

    deleteCookie() {
        this.gameCookieService.deleteCookie(this.defaultService.cookieName);
    }

    updateGame(
        homeTeamGoals: number,
        awayTeamGoals: number
    ) {
        this.gameData.half === 1 ? this.gameData.firstHalf.homeTeam.numGoals = homeTeamGoals : this.gameData.secondHalf.homeTeam.numGoals = homeTeamGoals;
        this.gameData.half === 1 ? this.gameData.firstHalf.awayTeam.numGoals = awayTeamGoals : this.gameData.secondHalf.awayTeam.numGoals = awayTeamGoals;
    }
}
