import { TermsComponent } from './terms/terms.component';
import { HelpComponent } from './help/help.component';
import { SummaryComponent } from './summary/summary.component';
import { SetupComponent } from './setup/setup.component';
import { HomeComponent } from './home/home.component';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'setup', component: SetupComponent },
  { path: 'summary', component: SummaryComponent },
  { path: 'help', component: HelpComponent },
  { path: 'terms', component: TermsComponent }
];

@NgModule({
  declarations: [],
  imports: [ RouterModule.forRoot(appRoutes) ],
  exports: [RouterModule]
})
export class RoutingModule { }
