import { GameService } from './game.service';
import { CookieService } from 'ngx-cookie-service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(private gameService: GameService, private cookie: CookieService) {}
  countDownTimeInSeconds: any;
  expiredDate: Date;


  ngOnInit() {
  }
}
