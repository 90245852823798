import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { GameService } from '../game.service';
import { GamedataInterface } from '../gamedata.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css']
})
export class SetupComponent implements OnInit, OnDestroy {
  @Output() coundownTimerChange = new EventEmitter();
  gameData: GamedataInterface;
  disabled: string;

  constructor(public gameService: GameService, private router: Router) {
    this.gameData = this.gameService.game.gameData;
    if (this.gameData.gameStatus !== 10) {
      this.disabled = 'disabled';
    }
   }

  counDownTimerChanged() {
    this.gameData.countdownValueOriginalSeconds = this.gameData.settings.oneHalfDuration * 60;
  }

  setNumHalfs(num: number) {
    if (!this.disabled) { this.gameData.settings.numHalfs = num; }
    /*console.log(this.gameData);*/
  }

  setRunsAfterGoal(runs: number) {
    if (!this.disabled) { this.gameData.settings.runsAfterGoal = runs ? true : false; }
  }

  saveChanges() {
    this.gameService.updateCookie().then( res => {
      this.router.navigate(['/']);
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.gameService.game.gameData.gameStatus === 11) {this.gameService.game.gameData.gameStatus = 12; }
    this.gameService.updateCookie().then( res => {
      this.router.navigate(['/']);
    });
  }
}
