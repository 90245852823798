import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  isTest: boolean;
  constructor() {
    this.isTest = true;
  }
  log(message: any) {
    if (this.isTest) {
      console.log(message);
    }
  }
}
