import { Injectable } from '@angular/core';
import { Game } from './game';
import { GamecookieService } from './gamecookie.service';
import { LogService } from './log.service';
import { Goal } from './goal.interface';

@Injectable({
  providedIn: 'root'
})
export class GameService {
  /* This service is available to the home, setup and summary components
     Initially it will request a game object (which returns a game from a cookie or creates a default one)
     Once the game is returned, this service takes over
  */

  /* --------------------------------------------------------------------------------------------- */
  // START
  /* --------------------------------------------------------------------------------------------- */

  /* --------------------------------------------------------------------------------------------- */
  // The following variables are used in the injected component directly.
  // This service will be responsible for periodically updating its game class as needs be
  /* --------------------------------------------------------------------------------------------- */

  /* inside the game object. No need to instantiate them here

    gameStatus: number;
    half: number;
    countdownValueOriginalSeconds: number;
    countdownValue: number;
    updateInterval: number;
    startDate: string;
    startDateMilliseconds: number;
    EndDate: string;
    EndDateMilliseconds: number;
    fileSaveName: string;
    control: {
        nameHomeTeam: string;
        nameAwayTeam: string;
        goalsDetails: Goal[];
    };
    tourno: {
        tournament: string;
        competition: string;
        game: string;
        field: string;
    };
    settings: {
        numHalfs: number;
        oneHalfDuration: number;
        inactivityTimeout: number;
        passString: number;
        runsAfterGoal: boolean;
    };
    firstHalf: {
        homeTeam: {
            numGoals: number;
            numshots: number;
            countFirstTouch: number;
            countPass: number;
            percentagePassCompletion: number;
            millisecondsPossession: number;
            percentagePossession: number;
            countPassStrings: number;
            passesDetails: {};
        };
        awayTeam: {
            numGoals: number;
            numshots: number;
            countFirstTouch: number;
            countPass: number;
            percentagePassCompletion: number;
            millisecondsPossession: number;
            percentagePossession: number;
            countPassStrings: number;
            passesDetails: {};
        };
    };
    secondHalf: {
        homeTeam: {
            numGoals: number;
            numshots: number;
            countFirstTouch: number;
            countPass: number;
            percentagePassCompletion: number;
            millisecondsPossession: number;
            percentagePossession: number;
            countPassStrings: number;
            passesDetails: {};
        };
        awayTeam: {
            numGoals: number;
            numshots: number;
            countFirstTouch: number;
            countPass: number;
            percentagePassCompletion: number;
            millisecondsPossession: number;
            percentagePossession: number;
            countPassStrings: number;
            passesDetails: {};
        };
    };


  */






  /* isTest */
  isTest: boolean;

  /* Common */
  // resettable
  cdownValue: number;
  passStringsCounter: number;
  // NOT resettable
  goalsDetails: Goal[];
  numGoalsTeam1: number;
  numGoalsTeam2: number;

  /* TEAM 1 */
  numHalfGoalsTeam1: number;
  numShotsTeam1: number;
  // resettable when changing halfs
  firstTouchCountTeam1: number;
  passCountTeam1: number;
  passCompletionPercentageTeam1: number;
  possessionTimeMillisecondsTeam1: number;
  possessionPercentageTeam1: number;
  passesDetailsTeam1;
  passStringsTeam1: number;
  passCountToGoalTeam1: number;

  /* TEAM 2 */
  numHalfGoalsTeam2: number;
  numShotsTeam2: number;
  // resettable when changing halfs
  firstTouchCountTeam2: number;
  passCountTeam2: number;
  passCompletionPercentageTeam2: number;
  possessionTimeMillisecondsTeam2: number;
  possessionPercentageTeam2: number;
  passesDetailsTeam2;
  passStringsTeam2: number;
  passCountToGoalTeam2: number;

  /* --------------------------------------------------------------------------------------------- */
  // END - all variables are given a value in the constructor of this service
  /* --------------------------------------------------------------------------------------------- */

  possessionTimeMinutesTeam1: number;
  possessionTimeSecondsTeam1: number;
  possessionTimeStringTeam1: string;
  possessionTimerRunningTeam1: boolean;

  possessionTimeMinutesTeam2: number;
  possessionTimeSecondsTeam2: number;
  possessionTimeStringTeam2: string;
  possessionTimerRunningTeam2: boolean;

  constructor(
    public game: Game,
    private logger: LogService,
    private gameCookieService: GamecookieService
    ) {
      /* Team 1 */
      this.numGoalsTeam1 = this.game.gameData.control.numGoalsTeam1;
      this.numHalfGoalsTeam1 = this.game.gameData.half === 1 ? this.game.gameData.firstHalf.homeTeam.numGoals : this.game.gameData.secondHalf.homeTeam.numGoals;
      this.numShotsTeam1 = this.game.gameData.half === 1 ? this.game.gameData.firstHalf.homeTeam.numShots : this.game.gameData.secondHalf.homeTeam.numShots;
      this.firstTouchCountTeam1 = this.game.gameData.half === 1 ? this.game.gameData.firstHalf.homeTeam.countFirstTouch : this.game.gameData.secondHalf.homeTeam.countFirstTouch;
      this.passCountTeam1 = this.game.gameData.half === 1 ? this.game.gameData.firstHalf.homeTeam.countPass : this.game.gameData.secondHalf.homeTeam.countPass;
      this.passCompletionPercentageTeam1 = this.game.gameData.half === 1 ? this.game.gameData.firstHalf.homeTeam.percentagePassCompletion : this.game.gameData.secondHalf.homeTeam.percentagePassCompletion;
      this.possessionTimeMillisecondsTeam1 = this.game.gameData.half === 1 ? this.game.gameData.firstHalf.homeTeam.millisecondsPossession : this.game.gameData.secondHalf.homeTeam.millisecondsPossession;
      this.possessionPercentageTeam1 = this.game.gameData.half === 1 ? this.game.gameData.firstHalf.homeTeam.percentagePossession : this.game.gameData.secondHalf.homeTeam.percentagePossession;
      this.passStringsTeam1 = this.game.gameData.half === 1 ? this.game.gameData.firstHalf.homeTeam.countPassStrings : this.game.gameData.secondHalf.homeTeam.countPassStrings;
      this.passesDetailsTeam1 = this.game.gameData.half === 1 ? this.game.gameData.firstHalf.homeTeam.passesDetails : this.game.gameData.secondHalf.homeTeam.passesDetails;

      /* Team 2 */
      this.numGoalsTeam2 = this.game.gameData.control.numGoalsTeam2;
      this.numHalfGoalsTeam2 = this.game.gameData.half === 1 ? this.game.gameData.firstHalf.awayTeam.numGoals : this.game.gameData.secondHalf.awayTeam.numGoals;
      this.numShotsTeam2 = this.game.gameData.half === 1 ? this.game.gameData.firstHalf.awayTeam.numShots : this.game.gameData.secondHalf.awayTeam.numShots;
      this.firstTouchCountTeam2 = this.game.gameData.half === 1 ? this.game.gameData.firstHalf.awayTeam.countFirstTouch : this.game.gameData.secondHalf.awayTeam.countFirstTouch;
      this.passCountTeam2 = this.game.gameData.half === 1 ? this.game.gameData.firstHalf.awayTeam.countPass : this.game.gameData.secondHalf.awayTeam.countPass;
      this.passCompletionPercentageTeam2 = this.game.gameData.half === 1 ? this.game.gameData.firstHalf.awayTeam.percentagePassCompletion : this.game.gameData.secondHalf.awayTeam.percentagePassCompletion;
      this.possessionTimeMillisecondsTeam2 = this.game.gameData.half === 1 ? this.game.gameData.firstHalf.awayTeam.millisecondsPossession : this.game.gameData.secondHalf.awayTeam.millisecondsPossession;
      this.possessionPercentageTeam2 = this.game.gameData.half === 1 ? this.game.gameData.firstHalf.awayTeam.percentagePossession : this.game.gameData.secondHalf.awayTeam.percentagePossession;
      this.passStringsTeam2 = this.game.gameData.half === 1 ? this.game.gameData.firstHalf.awayTeam.countPassStrings : this.game.gameData.secondHalf.awayTeam.countPassStrings;
      this.passesDetailsTeam2 = this.game.gameData.half === 1 ? this.game.gameData.firstHalf.awayTeam.passesDetails : this.game.gameData.secondHalf.awayTeam.passesDetails;

      this.possessionTimeStringTeam1 = this.possessionTimeStringTeam2 = '00:00';
      this.possessionTimerRunningTeam1 = this.possessionTimerRunningTeam2 = false;

      this.initialiseGameData();

  }

  /* NOT clear if the function below is needed or not */
  initialiseGameData() {
    if (this.possessionTimeMillisecondsTeam1 > 0) {
      // tslint:disable-next-line:no-bitwise
        this.possessionTimeMinutesTeam1 = Math.floor((this.possessionTimeMillisecondsTeam1 / 1000 / 60) << 0),
        this.possessionTimeSecondsTeam1 = Math.floor((this.possessionTimeMillisecondsTeam1 / 1000) % 60);
        this.possessionTimeStringTeam1 = (this.possessionTimeMinutesTeam1 === 0 || this.possessionTimeMinutesTeam1 < 10 ? '0' + this.possessionTimeMinutesTeam1 : this.possessionTimeMinutesTeam1) + ':' + (this.possessionTimeSecondsTeam1 === 0 || this.possessionTimeSecondsTeam1 < 10 ? '0' + this.possessionTimeSecondsTeam1 : this.possessionTimeSecondsTeam1);
    }

    if (this.possessionTimeMillisecondsTeam2 > 0) {
        // tslint:disable-next-line:no-bitwise
        this.possessionTimeMinutesTeam2 = Math.floor((this.possessionTimeMillisecondsTeam2 / 1000 / 60) << 0),
        this.possessionTimeSecondsTeam2 = Math.floor((this.possessionTimeMillisecondsTeam2 / 1000) % 60);
        this.possessionTimeStringTeam2 = (this.possessionTimeMinutesTeam2 === 0 || this.possessionTimeMinutesTeam2 < 10 ? '0' + this.possessionTimeMinutesTeam2 : this.possessionTimeMinutesTeam2) + ':' + (this.possessionTimeSecondsTeam2 === 0 || this.possessionTimeSecondsTeam2 < 10 ? '0' + this.possessionTimeSecondsTeam2 : this.possessionTimeSecondsTeam2);
    }

    this.goalsDetails = this.game.gameData.control.goalsDetails;
    this.passCountToGoalTeam1 = this.game.gameData.control.passCountToGoalTeam1;
    this.passCountToGoalTeam2 = this.game.gameData.control.passCountToGoalTeam2;

    if (this.game.gameData.gameStatus === 11) { this.game.gameData.gameStatus = 12; }
    if (this.game.gameData.gameStatus === 21) { this.game.gameData.gameStatus = 22; }

    this.logger.log('this.game.gameData.gameStatus = ' + this.game.gameData.gameStatus);

    this.cdownValue = this.game.gameData.gameStatus === 12 || this.game.gameData.gameStatus === 22 ? this.game.gameData.countdownValue / 1000 : this.game.gameData.countdownValueOriginalSeconds;
    this.game.cookieUpdateCounter = 0;

    this.isTest = false;

    this.updateCookie();
  }

  setTimers(half: number) {
    this.game.gameData.startDate = new Date().toLocaleString();
    this.game.gameData.fileSaveName = this.yyyymmdd()  + this.game.gameData.control.nameHomeTeam.replace(' ', '_') + '_vs_' + this.game.gameData.control.nameAwayTeam.replace(' ', '_');
    half === 1 ? this.game.gameData.firstHalfStartDateMilliseconds = new Date().getTime() : this.game.gameData.secondHalfStartDateMilliseconds = new Date().getTime();
   }

   nullTimers() {
    this.game.gameData.startDate = null;
    this.game.gameData.firstHalfStartDateMilliseconds = this.game.gameData.firstHalfStartDateMilliseconds = 0;
   }

   yyyymmdd() {
    const x = new Date();
    const y = x.getFullYear().toString();
    let m = (x.getMonth() + 1).toString();
    let d = x.getDate().toString();
    let h = x.getHours().toString();
    let min = x.getMinutes().toString();
    if (h.length === 1) {h = '0' + h; }
    if (min.length === 1) {min = '0' + min; }
    if (d.length === 1) {d = '0' + d; }
    if (m.length === 1) {m = '0' + m; }
    const yyyymmdd = y + m + d + '_' + h + min + '_';
    return yyyymmdd;
}

millisecontsToTime(duration: number, showWhole: boolean = false) {
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  let hoursString = hours.toString();

  const minutes = (Math.floor((duration / (1000 * 60)) % 60));

  let minutesString = minutes.toString();

  const seconds = Math.floor((duration / 1000) % 60);
  let secondsString = seconds.toString();

  const milliseconds = Math.floor((duration % 1000) / 100);
  let millisecondsString = milliseconds.toString();

  hoursString = (hours < 10) ? '0' + hoursString : hoursString;
  minutesString = (minutes < 10) ? '0' + minutesString : minutesString;
  secondsString = (seconds < 10) ? '0' + secondsString : secondsString;
  millisecondsString = (milliseconds < 10) ? '0' + millisecondsString : millisecondsString;

  return showWhole ? hoursString + ':' + minutesString + ':' + secondsString + '.' + millisecondsString : minutesString + ':' + secondsString;
}

  updateTeamNames() {
    this.updateCookie();
  }

  updateFirstTouchPassCount(team: number) {
    if (this.game.gameData.half === 1) {
      if (team === 1) {
        this.game.gameData.firstHalf.homeTeam.countFirstTouch = this.firstTouchCountTeam1;
        this.game.gameData.firstHalf.homeTeam.countPass = this.passCountTeam1;
        // this.game.gameData.firstHalf.homeTeam.percentagePassCompletion =
        this.game.gameData.firstHalf.homeTeam.countPassStrings = this.passStringsTeam1;
        this.game.gameData.firstHalf.homeTeam.passesDetails = this.passesDetailsTeam1;
        this.game.gameData.control.passCountToGoalTeam1 = this.passCountToGoalTeam1;

      } else {
        this.game.gameData.firstHalf.awayTeam.countFirstTouch = this.firstTouchCountTeam2;
        this.game.gameData.firstHalf.awayTeam.countPass = this.passCountTeam2;
        this.game.gameData.firstHalf.awayTeam.countPassStrings = this.passStringsTeam2;
        this.game.gameData.firstHalf.awayTeam.passesDetails = this.passesDetailsTeam2;
        this.game.gameData.control.passCountToGoalTeam2 = this.passCountToGoalTeam2;
      }
    } else {
      if (team === 1) {
        this.game.gameData.secondHalf.homeTeam.countFirstTouch = this.firstTouchCountTeam1;
        this.game.gameData.secondHalf.homeTeam.countPass = this.passCountTeam1;
        this.game.gameData.secondHalf.homeTeam.countPassStrings = this.passStringsTeam1;
        this.game.gameData.secondHalf.homeTeam.passesDetails = this.passesDetailsTeam1;
        this.game.gameData.control.passCountToGoalTeam1 = this.passCountToGoalTeam1;
      } else {
        this.game.gameData.secondHalf.awayTeam.countFirstTouch = this.firstTouchCountTeam2;
        this.game.gameData.secondHalf.awayTeam.countPass = this.passCountTeam2;
        this.game.gameData.secondHalf.awayTeam.countPassStrings = this.passStringsTeam2;
        this.game.gameData.secondHalf.awayTeam.passesDetails = this.passesDetailsTeam2;
        this.game.gameData.control.passCountToGoalTeam2 = this.passCountToGoalTeam2;
      }
    }
  }

  updateGoalShotCount(team: number) {
    this.game.gameData.control.numGoalsTeam1 = this.numGoalsTeam1;
    this.game.gameData.control.numGoalsTeam2 = this.numGoalsTeam2;
    if (this.game.gameData.half === 1) {
      if (team === 1) {
        this.game.gameData.firstHalf.homeTeam.numGoals = this.numHalfGoalsTeam1;
        this.game.gameData.firstHalf.homeTeam.numShots = this.numShotsTeam1;
      } else {
        this.game.gameData.firstHalf.awayTeam.numGoals = this.numHalfGoalsTeam2;
        this.game.gameData.firstHalf.awayTeam.numShots = this.numShotsTeam2;
      }
    } else {
      if (team === 1) {
        this.game.gameData.secondHalf.homeTeam.numGoals = this.numHalfGoalsTeam1;
        this.game.gameData.secondHalf.homeTeam.numShots = this.numShotsTeam1;
      } else {
        this.game.gameData.secondHalf.awayTeam.numGoals = this.numHalfGoalsTeam2;
        this.game.gameData.secondHalf.awayTeam.numShots = this.numShotsTeam2;
      }
    }
  }

  updateTeamPossessionPercentage() {
    if (this.game.gameData.half === 1) {
      this.game.gameData.firstHalf.homeTeam.millisecondsPossession = this.possessionTimeMillisecondsTeam1;
      this.game.gameData.firstHalf.homeTeam.percentagePossession = this.possessionPercentageTeam1;
      this.game.gameData.firstHalf.awayTeam.millisecondsPossession = this.possessionTimeMillisecondsTeam2;
      this.game.gameData.firstHalf.awayTeam.percentagePossession = this.possessionPercentageTeam2;
    } else {
      this.game.gameData.secondHalf.homeTeam.millisecondsPossession = this.possessionTimeMillisecondsTeam1;
      this.game.gameData.secondHalf.homeTeam.percentagePossession = this.possessionPercentageTeam1;
      this.game.gameData.secondHalf.awayTeam.millisecondsPossession = this.possessionTimeMillisecondsTeam2;
      this.game.gameData.secondHalf.awayTeam.percentagePossession = this.possessionPercentageTeam2;
    }
  }

  updateTeamPassPercentage() {
    if (this.game.gameData.half === 1) {
      this.game.gameData.firstHalf.homeTeam.percentagePassCompletion = this.passCompletionPercentageTeam1;
      this.game.gameData.firstHalf.awayTeam.percentagePassCompletion = this.passCompletionPercentageTeam2;
    } else {
      this.game.gameData.secondHalf.homeTeam.percentagePassCompletion = this.passCompletionPercentageTeam1;
      this.game.gameData.secondHalf.awayTeam.percentagePassCompletion = this.passCompletionPercentageTeam2;
    }
  }

  addTeamGoalDetails(team: number) {

    const scoreMillisecond: number = this.game.gameData.half === 1 ? (this.game.gameData.countdownValueOriginalSeconds * 1000) - this.game.gameData.countdownValue : ((this.game.gameData.countdownValueOriginalSeconds * 1000) - this.game.gameData.countdownValue) + this.game.gameData.countdownValueOriginalSeconds * 1000;
    const scoreDateminute: string = this.millisecontsToTime(scoreMillisecond, false);

    const goal: Goal = {team: team === 1 ? 1 : 2, time: scoreDateminute, passes: team === 1 ? this.passCountToGoalTeam1 : this.passCountToGoalTeam2};
    this.logger.log(goal);
    this.goalsDetails.unshift(goal);
    this.game.gameData.control.goalsDetails = this.goalsDetails;
    // NOTE: the below counters are only reset when a goal is scored
    // Should they be reset on change of possession also?????????
    this.passCountToGoalTeam1 = this.passCountToGoalTeam2 = this.game.gameData.control.passCountToGoalTeam1 = this.game.gameData.control.passCountToGoalTeam2 = 0;
  }

  minusTeamGoalDetails(team: number) {
    let done = false;
    this.logger.log(this.goalsDetails);
    this.goalsDetails.forEach((myObject, index) => {
      if (myObject.team === team && !done) {
        done = true;
        this.goalsDetails.splice(index, 1);
      }
    });
    this.game.gameData.control.goalsDetails = this.goalsDetails;
  }

  updateTotalData() {
    /* here we create the summary component of the object (called 'total') */
    /* we do this is the #halfs > 1 (if #halfs == 1 then first half is the summary) */
    // goals
    this.game.gameData.total.homeTeam.numGoals = this.game.gameData.firstHalf.homeTeam.numGoals + this.game.gameData.secondHalf.homeTeam.numGoals;
    this.game.gameData.total.awayTeam.numGoals = this.game.gameData.firstHalf.awayTeam.numGoals + this.game.gameData.secondHalf.awayTeam.numGoals;
    // shots
    this.game.gameData.total.homeTeam.numShots = this.game.gameData.firstHalf.homeTeam.numShots + this.game.gameData.secondHalf.homeTeam.numShots;
    this.game.gameData.total.awayTeam.numShots = this.game.gameData.firstHalf.awayTeam.numShots + this.game.gameData.secondHalf.awayTeam.numShots;
    // first touches
    this.game.gameData.total.homeTeam.countFirstTouch = this.game.gameData.firstHalf.homeTeam.countFirstTouch + this.game.gameData.secondHalf.homeTeam.countFirstTouch;
    this.game.gameData.total.awayTeam.countFirstTouch = this.game.gameData.firstHalf.awayTeam.countFirstTouch + this.game.gameData.secondHalf.awayTeam.countFirstTouch;
    // passes
    this.game.gameData.total.homeTeam.countPass = this.game.gameData.firstHalf.homeTeam.countPass + this.game.gameData.secondHalf.homeTeam.countPass;
    this.game.gameData.total.awayTeam.countPass = this.game.gameData.firstHalf.awayTeam.countPass + this.game.gameData.secondHalf.awayTeam.countPass;
    // pass strings
    this.game.gameData.total.homeTeam.countPassStrings = this.game.gameData.firstHalf.homeTeam.countPassStrings + this.game.gameData.secondHalf.homeTeam.countPassStrings;
    this.game.gameData.total.awayTeam.countPassStrings = this.game.gameData.firstHalf.awayTeam.countPassStrings + this.game.gameData.secondHalf.awayTeam.countPassStrings;
    // pass completion %
    this.game.gameData.total.homeTeam.percentagePassCompletion = this.game.gameData.total.homeTeam.countFirstTouch ? Math.floor(this.game.gameData.total.homeTeam.countPass / this.game.gameData.total.homeTeam.countFirstTouch * 100) : 0;
    this.game.gameData.total.awayTeam.percentagePassCompletion = this.game.gameData.total.awayTeam.countFirstTouch ? Math.floor(this.game.gameData.total.awayTeam.countPass / this.game.gameData.total.awayTeam.countFirstTouch * 100) : 0;
    // possession time
    this.game.gameData.total.homeTeam.millisecondsPossession = this.game.gameData.firstHalf.homeTeam.millisecondsPossession + this.game.gameData.secondHalf.homeTeam.millisecondsPossession;
    this.game.gameData.total.awayTeam.millisecondsPossession = this.game.gameData.firstHalf.awayTeam.millisecondsPossession + this.game.gameData.secondHalf.awayTeam.millisecondsPossession;
    // possession %
    this.game.gameData.total.homeTeam.percentagePossession = this.game.gameData.total.homeTeam.millisecondsPossession ? Math.floor(this.game.gameData.total.homeTeam.millisecondsPossession / (this.game.gameData.total.homeTeam.millisecondsPossession + this.game.gameData.total.awayTeam.millisecondsPossession) * 100) : 0;
    this.game.gameData.total.awayTeam.percentagePossession = this.game.gameData.total.awayTeam.millisecondsPossession ? Math.floor(this.game.gameData.total.awayTeam.millisecondsPossession / (this.game.gameData.total.homeTeam.millisecondsPossession + this.game.gameData.total.awayTeam.millisecondsPossession) * 100) : 0;

    if (this.game.gameData.total.homeTeam.percentagePossession + this.game.gameData.total.awayTeam.percentagePossession !== 100) {
      if (this.game.gameData.total.homeTeam.percentagePossession > this.game.gameData.total.awayTeam.percentagePossession) {
        this.game.gameData.total.homeTeam.percentagePossession = this.game.gameData.total.homeTeam.percentagePossession + 1;
      } else {
        this.game.gameData.total.awayTeam.percentagePossession = this.game.gameData.total.awayTeam.percentagePossession + 1;
      }
    }

    for (let i = 0; i < this.game.gameData.firstHalf.homeTeam.passesDetails.length; i++) {
      this.game.gameData.total.homeTeam.passesDetails.push(this.game.gameData.firstHalf.homeTeam.passesDetails[i]);
    }
    for (let i = 0; i < this.game.gameData.secondHalf.homeTeam.passesDetails.length; i++) {
      this.game.gameData.total.homeTeam.passesDetails.push(this.game.gameData.secondHalf.homeTeam.passesDetails[i]);
    }

    for (let i = 0; i < this.game.gameData.firstHalf.awayTeam.passesDetails.length; i++) {
      this.game.gameData.total.awayTeam.passesDetails.push(this.game.gameData.firstHalf.awayTeam.passesDetails[i]);
    }
    for (let i = 0; i < this.game.gameData.secondHalf.awayTeam.passesDetails.length; i++) {
      this.game.gameData.total.awayTeam.passesDetails.push(this.game.gameData.secondHalf.awayTeam.passesDetails[i]);
    }

    this.updateCookie();

  }

  resetGame() {
    /* Reset all counters and cookie properties */
    this.numGoalsTeam1 = this.numGoalsTeam2 = 0;
    this.numHalfGoalsTeam1 = this.numHalfGoalsTeam2 = 0;
    this.numShotsTeam1 = this.numShotsTeam2 = 0;
    this.firstTouchCountTeam1 = this.firstTouchCountTeam2 = 0;
    this.passCountTeam1 = this.passCountTeam2 = 0;
    this.passCompletionPercentageTeam1 = this.firstTouchCountTeam2 = 0;
    this.possessionTimeMillisecondsTeam1 = this.possessionTimeMillisecondsTeam2 = 0;
    this.possessionTimeMinutesTeam1 = this.possessionTimeSecondsTeam1 = this.possessionTimeMillisecondsTeam1 = 0;
    this.possessionTimeMinutesTeam2 = this.possessionTimeSecondsTeam2 = this.possessionTimeMillisecondsTeam2 = 0;
    this.possessionPercentageTeam1 = this.possessionPercentageTeam2 = 0;
    this.possessionTimeStringTeam1 = this.possessionTimeStringTeam2 = '00:00';
    this.passStringsTeam1 = this.passStringsTeam2 = 0;
    this.passCountToGoalTeam1 = this.passCountToGoalTeam2 = 0;
    this.cdownValue = this.game.gameData.countdownValueOriginalSeconds;
    this.game.gameData.countdownValue = this.game.gameData.countdownValueOriginalSeconds;
    this.goalsDetails = [];
    this.game.initialiseGameData(
      true,
      this.game.gameData.settings.numHalfs,
      this.game.gameData.settings.oneHalfDuration,
      this.game.gameData.settings.inactivityTimeout,
      this.game.gameData.settings.passString,
      this.game.gameData.settings.runsAfterGoal,
      this.game.gameData.countdownValueOriginalSeconds,
      this.game.gameData.countdownValueOriginalSeconds,
      this.game.gameData.control.nameHomeTeam,
      this.game.gameData.control.nameAwayTeam,
      this.game.gameData.tourno.tournament,
      this.game.gameData.tourno.competition,
      this.game.gameData.tourno.game,
      this.game.gameData.tourno.fieldName,
      this.game.gameData.tourno.fieldType
    );

    // this.updateCookie();
  }

  deleteCookie() {
    this.game.deleteCookie();
  }

  updateGame() {
    this.game.gameData.half === 1 ? this.game.gameData.firstHalf.homeTeam.numGoals = this.numGoalsTeam1 : this.game.gameData.secondHalf.homeTeam.numGoals = this.numGoalsTeam1;
    // this.updateCookie();
  }

  updateCookie() {
    this.game.updateGameCookie();
    return new Promise((resolve, reject) => {
      resolve();
    });
  }
}
