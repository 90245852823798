import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-send-email-dialog',
  templateUrl: './send-email-dialog.component.html',
  styleUrls: ['./send-email-dialog.component.css']
})
export class SendEmailDialogComponent {
  tempEmailArray = [];
  emailArray = [];
  emailArrayContainsElements = false;
  addressCount = 0;
  constructor(private bottomSheetRef: MatBottomSheetRef<any>, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
    const emailString = data.addressList;
    this.tempEmailArray = emailString.length ? emailString.split(',') : [];
    this.tempEmailArray.forEach( (myObject, index) => {
      const address = myObject.replace(/\s/g, '');
      if (address.length) {
        this.addressCount++;
        // tslint:disable-next-line:object-literal-shorthand
        const tempArr = {address: address, issue: 0};
        this.emailArray.push(tempArr);
      }
    });
    if (this.addressCount > 0) { this.emailArrayContainsElements = true; }
  }
  sendEmail(option: number) {
    this.data.option = option;
    this.bottomSheetRef.dismiss(this.data);
  }

}
