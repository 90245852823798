import { SendEmailDialogComponent } from './send-email-dialog/send-email-dialog.component';
import { SaveFormatDialogComponent } from './save-format-dialog/save-format-dialog.component';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { GameService } from '../game.service';
import * as fs from 'file-saver';
import { DatePipe } from '@angular/common';

import { LogService } from '../log.service';
import { GamedataInterface } from '../gamedata.interface';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {


  data;
  gameSaveName: string;
  gameSaved: boolean;
  gameEmailAddresses: string;
  gameEmailSendResult: JSON;
  gameEmailInputMessage: string;

  gamePDFResult: JSON;
  gamePDFid: string;

  game;
  gameJSON;
  general;

  summaryEmailDataString: string;
  emailContent: string;
  error = '';
  success = '';
  emailSent: boolean;
  basePath: string;
  test: string;
  gameSummary: GamedataInterface;

  firstHalfTeam1LongestPassString: number;
  firstHalfTeam1AveragePassString: number;
  firstHalfTeam2LongestPassString: number;
  firstHalfTeam2AveragePassString: number;

  secondHalfTeam1LongestPassString: number;
  secondHalfTeam1AveragePassString: number;
  secondHalfTeam2LongestPassString: number;
  secondHalfTeam2AveragePassString: number;

  totalTeam1LongestPassString: number;
  totalTeam1AveragePassString: number;
  totalTeam2LongestPassString: number;
  totalTeam2AveragePassString: number;

  totalPasses: number;

  // @ViewChild('dataContainer') dataContainer: ElementRef;
  @ViewChild('pdfTable') pdfTable: ElementRef;

  // tslint:disable: prefer-const

  constructor(
    private gameService: GameService,
    private logger: LogService,
    private bottomSheet: MatBottomSheet,
    private datePipe: DatePipe
    ) {
    this.gameSummary = this.gameService.game.gameData;
  }

  ngOnInit() {
    this.logger.log(this.gameSummary);

    /* if user goes to Summary mid game */
    if (this.gameService.game.gameData.gameStatus === 11) {this.gameService.game.gameData.gameStatus = 12; }
    if (this.gameService.game.gameData.gameStatus === 21) {this.gameService.game.gameData.gameStatus = 22; }

    this.gameSaveName = this.gameSummary.fileSaveName;
    this.game = {};

    // this.data = this.dataContainer.nativeElement.innerHTML;
    /* console.log(this.data); */
    this.wrapGame();

    this.gameEmailAddresses = '';
    this.emailSent = false;
    this.gameEmailInputMessage = 'comma separated email addresses';

    this.basePath = this.gameService.isTest ? 'http://localhost/focus/tourno/' : 'https://tourno.fi/';

    /* set up the pass strings */
    this.firstHalfTeam1LongestPassString = this.firstHalfTeam1AveragePassString = this.firstHalfTeam2LongestPassString = this.firstHalfTeam2AveragePassString = 0;
    this.secondHalfTeam1LongestPassString = this.secondHalfTeam1AveragePassString = this.secondHalfTeam2LongestPassString = this.secondHalfTeam2AveragePassString = 0;
    this.totalTeam1LongestPassString = this.totalTeam1AveragePassString = this.totalTeam2LongestPassString = this.totalTeam2AveragePassString = 0;

    this.gameSummary.firstHalf.homeTeam.passesDetails.forEach((num) => { if (num > this.firstHalfTeam1LongestPassString) { this.firstHalfTeam1LongestPassString = num; }});
    this.firstHalfTeam1AveragePassString = this.gameSummary.secondHalf.homeTeam.passesDetails.length ? Math.floor(this.gameSummary.firstHalf.homeTeam.countPass / this.gameSummary.firstHalf.homeTeam.passesDetails.length) : 0;
    this.gameSummary.firstHalf.awayTeam.passesDetails.forEach((num) => { if (num > this.firstHalfTeam2LongestPassString) { this.firstHalfTeam2LongestPassString = num; }});
    this.firstHalfTeam2AveragePassString = this.gameSummary.firstHalf.awayTeam.passesDetails.length ? Math.floor(this.gameSummary.firstHalf.awayTeam.countPass / this.gameSummary.firstHalf.awayTeam.passesDetails.length) : 0;

    this.gameSummary.secondHalf.homeTeam.passesDetails.forEach((num) => { if (num > this.secondHalfTeam1LongestPassString) { this.secondHalfTeam1LongestPassString = num; }});
    this.secondHalfTeam1AveragePassString = this.gameSummary.secondHalf.homeTeam.passesDetails.length ? Math.floor(this.gameSummary.secondHalf.homeTeam.countPass / this.gameSummary.secondHalf.homeTeam.passesDetails.length) : 0;
    this.gameSummary.secondHalf.awayTeam.passesDetails.forEach((num) => { if (num > this.secondHalfTeam2LongestPassString) { this.secondHalfTeam2LongestPassString = num; }});
    this.secondHalfTeam2AveragePassString = this.gameSummary.secondHalf.awayTeam.passesDetails.length ? Math.floor(this.gameSummary.secondHalf.awayTeam.countPass / this.gameSummary.secondHalf.awayTeam.passesDetails.length) : 0;

    this.totalTeam1LongestPassString = this.firstHalfTeam1LongestPassString > this.secondHalfTeam1LongestPassString ? this.firstHalfTeam1LongestPassString : this.secondHalfTeam1LongestPassString;
    this.totalTeam1AveragePassString = this.gameSummary.total.homeTeam.passesDetails.length ? Math.floor((this.gameSummary.firstHalf.homeTeam.countPass + this.gameSummary.secondHalf.homeTeam.countPass) / (this.gameSummary.total.homeTeam.passesDetails.length)) : 0;
    this.totalTeam2LongestPassString = this.firstHalfTeam2LongestPassString > this.secondHalfTeam2LongestPassString ? this.firstHalfTeam2LongestPassString : this.secondHalfTeam2LongestPassString;
    this.totalTeam2AveragePassString = this.gameSummary.total.awayTeam.passesDetails.length ? Math.floor((this.gameSummary.firstHalf.awayTeam.countPass + this.gameSummary.secondHalf.awayTeam.countPass) / (this.gameSummary.total.awayTeam.passesDetails.length)) : 0;
  }

  wrapGame() {
    this.game = {
    };

    this.gameJSON = JSON.stringify(this.game);
  }

  updateGameSaveName() {

  }

  saveGame() {
    const bottomSheetRef = this.bottomSheet.open(SaveFormatDialogComponent, {
      disableClose: true,
      data: {option: 0, fileName: this.gameSaveName}
      });

    bottomSheetRef.afterDismissed().subscribe(result => {
      console.log(result);
      if (result.option === 1) { this.saveGamePDF(); }
      if (result.option === 2) { this.saveGameXLSX(); }
    });
  }

  saveGamePDF() {
    let topColumns = [''];
    let topRows = [];

    topRows.push(['Generated by GameStats (free game measurement software)']);
    topRows.push(['https://gamestats.fi/']);
    topRows.push(['Owner: CR3Data']);


    let controlColumns = ['Data', 'Value'];
    let controlRows = [];
    let fieldName = this.gameSummary.tourno.fieldName ? this.gameSummary.tourno.fieldName : '';
    let fieldType = this.gameSummary.tourno.fieldType ? ' (' + this.gameSummary.tourno.fieldType + ')' : '';
    let field = fieldName ? fieldName + fieldType : '';

    let homeTeamName = this.gameSummary.control.nameHomeTeam ? this.gameSummary.control.nameHomeTeam : '';
    let homeTeamClub = this.gameSummary.control.nameHomeTeamClub ? ' (' + this.gameSummary.control.nameHomeTeamClub + ')' : '';
    let homeTeamFormation = this.gameSummary.control.formationHomeTeam ? ' - Formation: ' + this.gameSummary.control.formationHomeTeam : '';
    let homeTeam = homeTeamName ? homeTeamName + homeTeamClub + homeTeamFormation : '';

    let awayTeamName = this.gameSummary.control.nameAwayTeam ? this.gameSummary.control.nameAwayTeam : '';
    let awayTeamClub = this.gameSummary.control.nameAwayTeamClub ? ' (' + this.gameSummary.control.nameAwayTeamClub + ')' : '';
    let awayTeamFormation = this.gameSummary.control.formationAwayTeam ? ' - Formation: ' + this.gameSummary.control.formationAwayTeam : '';
    let awayTeam = awayTeamName ? awayTeamName + awayTeamClub + awayTeamFormation : '';


    if (this.gameSummary.tourno.tournament) { controlRows.push(['Tournament', this.gameSummary.tourno.tournament]); }
    if (this.gameSummary.tourno.competition) { controlRows.push(['Competition', this.gameSummary.tourno.tournament]); }
    if (field) { controlRows.push(['Field', field]); }
    if (this.gameSummary.tourno.game) { controlRows.push(['Game', this.gameSummary.tourno.game]); }
    if (homeTeam) { controlRows.push(['Home Team', homeTeam]); }
    if (awayTeam) { controlRows.push(['Away Team', awayTeam]); }

    const doc = new jsPDF('p', 'pt');
    /*const specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };*/

    // doc.autoTable();
    doc.autoTable(topColumns, topRows, {pageBreak: 'avoid', styles: {fontStyle: 'bold'}});
    if (controlRows.length) { doc.autoTable(controlColumns, controlRows, {pageBreak: 'avoid'}); }

    doc.autoTable({html: '#pdfTableSettings', pageBreak: 'avoid'});
    doc.autoTable({html: '#pdfTableFirstHalf', pageBreak: 'avoid', styles: {halign: 'center'}});
    if (this.gameSummary.settings.numHalfs > 1) {
      doc.autoTable({html: '#pdfTableSecondHalf', pageBreak: 'avoid', styles: {halign: 'center'}});
      doc.autoTable({html: '#pdfTableTotal', pageBreak: 'avoid', styles: {halign: 'center'}});
    }

    doc.autoTable({html: '#pdfTableGoals', pageBreak: 'avoid', styles: {halign: 'center'}});
    doc.save(this.gameSaveName + '.pdf');
  }

  saveGameXLSX() {
      const Excel = require('exceljs');
      console.log(Excel);

      let workbook = new Excel.Workbook();

      /**********************************************************************************************/
      /* Create first tab called 'Tables' which is a representation of the results in table format  */
      /**********************************************************************************************/

      let worksheetTables = workbook.addWorksheet('Tables');
      const title = 'GameStats Report';
      let titleRow = worksheetTables.addRow([title]);
      titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true };
      worksheetTables.addRow([]);
      worksheetTables.addRow(['Date : ' + this.datePipe.transform(new Date(), 'medium')]);

      worksheetTables.mergeCells('A1:C2');
      worksheetTables.addRow([]);

      let infoRow1 = worksheetTables.addRow(['Generated by GameStats (free game measurement software)']);
      infoRow1.font = { bold: true };
      let infoRow2 = worksheetTables.addRow(['https://gamestats.fi/']);
      infoRow2.font = { bold: true };
      let infoRow3 = worksheetTables.addRow(['Owner: CR3Data']);
      infoRow3.font = { bold: true };

      worksheetTables.addRow([]);

      const headerData = ['Data', 'Value'];
      let headerRowData = worksheetTables.addRow(headerData);
      headerRowData.eachCell((cell, numb) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF00' },
          bgColor: { argb: 'FF0000FF' }
        };
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        cell.alignment = { vertical: 'middle', horizontal: 'left' };
      });
      const dataData = [
        ['Home Team',  this.gameSummary.control.nameHomeTeam],
        ['Away Team',  this.gameSummary.control.nameAwayTeam]
      ];

      dataData.forEach(d => {
        let row = worksheetTables.addRow(d);
        let c1 = row.getCell(1);
        let c2 = row.getCell(2);
        c1.alignment = { vertical: 'middle', horizontal: 'left' };
        c2.alignment = { vertical: 'middle', horizontal: 'left' };
      });

      worksheetTables.addRow([]);


      const headerSettings = ['Setting', 'Value'];
      let headerRowSetings = worksheetTables.addRow(headerSettings);
      headerRowSetings.eachCell((cell, numb) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF00' },
          bgColor: { argb: 'FF0000FF' }
        };
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        cell.alignment = { vertical: 'middle', horizontal: 'left' };
      });
      const dataSettings = [
        ['Number of halfs',  this.gameSummary.settings.numHalfs],
        ['Half Duration',  this.gameSummary.settings.oneHalfDuration + ' minutes'],
        ['Inactivity timeout',  this.gameSummary.settings.inactivityTimeout + ' seconds'],
        ['Minimum passes in pass string',  this.gameSummary.settings.passString + ' passes'],
        ['Software',  this.gameSummary.settings.softwareName + ' (v' + this.gameSummary.settings.softwareVersion + ')']
      ];

      dataSettings.forEach(d => {
        let row = worksheetTables.addRow(d);
        let c1 = row.getCell(1);
        let c2 = row.getCell(2);
        c1.alignment = { vertical: 'middle', horizontal: 'left' };
        c2.alignment = { vertical: 'middle', horizontal: 'left' };
      });

      worksheetTables.addRow([]);


      const headerFirstHalf = [this.gameSummary.control.nameHomeTeam, this.gameSummary.settings.numHalfs > 1 ? '1st Half' : 'Summary', this.gameSummary.control.nameAwayTeam];
      let headerRowFirstHalf = worksheetTables.addRow(headerFirstHalf);
      headerRowFirstHalf.eachCell((cell, numb) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF00' },
          bgColor: { argb: 'FF0000FF' }
        };
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
      });
      const dataFirstHalf = [
        [this.gameSummary.firstHalf.homeTeam.numGoals, 'Goals', this.gameSummary.firstHalf.awayTeam.numGoals],
        [this.gameSummary.firstHalf.homeTeam.numShots, 'Shots', this.gameSummary.firstHalf.awayTeam.numShots],
        [this.gameService.millisecontsToTime(this.gameSummary.firstHalf.homeTeam.millisecondsPossession), 'Ball Possession Time', this.gameService.millisecontsToTime(this.gameSummary.firstHalf.awayTeam.millisecondsPossession)],
        [this.gameSummary.firstHalf.homeTeam.percentagePossession, 'Ball Possession %', this.gameSummary.firstHalf.awayTeam.percentagePossession],
        [this.gameSummary.firstHalf.homeTeam.countFirstTouch, 'First Touches', this.gameSummary.firstHalf.awayTeam.countFirstTouch],
        [this.gameSummary.firstHalf.homeTeam.countPass, 'Passes', this.gameSummary.firstHalf.awayTeam.countPass],
        [this.gameSummary.firstHalf.homeTeam.percentagePassCompletion, 'Pass Completion %', this.gameSummary.firstHalf.awayTeam.percentagePassCompletion],
        [this.gameSummary.firstHalf.homeTeam.countPassStrings, 'Number of Pass Strings', this.gameSummary.firstHalf.awayTeam.countPassStrings],
        [this.firstHalfTeam1AveragePassString, 'Average Pass String Length', this.firstHalfTeam2AveragePassString],
        [this.firstHalfTeam1LongestPassString, 'Longest Pass String', this.firstHalfTeam2LongestPassString]
      ];

      dataFirstHalf.forEach(d => {
        let row = worksheetTables.addRow(d);
        let c1 = row.getCell(1);
        let c2 = row.getCell(2);
        let c3 = row.getCell(3);
        c1.alignment = { vertical: 'middle', horizontal: 'center' };
        c2.alignment = { vertical: 'middle', horizontal: 'center' };
        c3.alignment = { vertical: 'middle', horizontal: 'center' };
      });

      worksheetTables.addRow([]);


      if (this.gameSummary.settings.numHalfs > 1) {
        const headerSecondHalf = [this.gameSummary.control.nameHomeTeam, '2nd Half', this.gameSummary.control.nameAwayTeam];
        let headerRowSecondHalf = worksheetTables.addRow(headerSecondHalf);
        headerRowSecondHalf.eachCell((cell, numb) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFFFF00' },
            bgColor: { argb: 'FF0000FF' }
          };
          cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        });
        const dataSecondHalf = [
          [this.gameSummary.secondHalf.homeTeam.numGoals, 'Goals', this.gameSummary.secondHalf.awayTeam.numGoals],
          [this.gameSummary.secondHalf.homeTeam.numShots, 'Shots', this.gameSummary.secondHalf.awayTeam.numShots],
          [this.gameService.millisecontsToTime(this.gameSummary.secondHalf.homeTeam.millisecondsPossession), 'Ball Possession Time', this.gameService.millisecontsToTime(this.gameSummary.secondHalf.awayTeam.millisecondsPossession)],
          [this.gameSummary.secondHalf.homeTeam.percentagePossession, 'Ball Possession %', this.gameSummary.secondHalf.awayTeam.percentagePossession],
          [this.gameSummary.secondHalf.homeTeam.countFirstTouch, 'First Touches', this.gameSummary.secondHalf.awayTeam.countFirstTouch],
          [this.gameSummary.secondHalf.homeTeam.countPass, 'Passes', this.gameSummary.secondHalf.awayTeam.countPass],
          [this.gameSummary.secondHalf.homeTeam.percentagePassCompletion, 'Pass Completion %', this.gameSummary.secondHalf.awayTeam.percentagePassCompletion],
          [this.gameSummary.secondHalf.homeTeam.countPassStrings, 'Number of Pass Strings', this.gameSummary.secondHalf.awayTeam.countPassStrings],
          [this.secondHalfTeam1AveragePassString, 'Average Pass String Length', this.secondHalfTeam2AveragePassString],
          [this.secondHalfTeam1LongestPassString, 'Longest Pass String', this.secondHalfTeam2LongestPassString]
        ];

        dataSecondHalf.forEach(d => {
          let row = worksheetTables.addRow(d);
          let c1 = row.getCell(1);
          let c2 = row.getCell(2);
          let c3 = row.getCell(3);
          c1.alignment = { vertical: 'middle', horizontal: 'center' };
          c2.alignment = { vertical: 'middle', horizontal: 'center' };
          c3.alignment = { vertical: 'middle', horizontal: 'center' };
        });

        worksheetTables.addRow([]);

        const headerTotal = [this.gameSummary.control.nameHomeTeam, 'Game Total', this.gameSummary.control.nameAwayTeam];
        let headerRowTotal = worksheetTables.addRow(headerTotal);
        // tslint:disable-next-line:variable-name
        headerRowTotal.eachCell((cell, numb) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFFFF00' },
            bgColor: { argb: 'FF0000FF' }
          };
          cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        });
        const dataTotal = [
          [this.gameSummary.total.homeTeam.numGoals, 'Goals', this.gameSummary.total.awayTeam.numGoals],
          [this.gameSummary.total.homeTeam.numShots, 'Shots', this.gameSummary.total.awayTeam.numShots],
          [this.gameService.millisecontsToTime(this.gameSummary.total.homeTeam.millisecondsPossession), 'Ball Possession Time', this.gameService.millisecontsToTime(this.gameSummary.total.awayTeam.millisecondsPossession)],
          [this.gameSummary.total.homeTeam.percentagePossession, 'Ball Possession %', this.gameSummary.total.awayTeam.percentagePossession],
          [this.gameSummary.total.homeTeam.countFirstTouch, 'First Touches', this.gameSummary.total.awayTeam.countFirstTouch],
          [this.gameSummary.total.homeTeam.countPass, 'Passes', this.gameSummary.total.awayTeam.countPass],
          [this.gameSummary.total.homeTeam.percentagePassCompletion, 'Pass Completion %', this.gameSummary.total.awayTeam.percentagePassCompletion],
          [this.gameSummary.total.homeTeam.countPassStrings, 'Number of Pass Strings', this.gameSummary.total.awayTeam.countPassStrings],
          [this.totalTeam1AveragePassString, 'Average Pass String Length', this.totalTeam2AveragePassString],
          [this.totalTeam1LongestPassString, 'Longest Pass String', this.totalTeam2LongestPassString]
        ];

        dataTotal.forEach(d => {
          let row = worksheetTables.addRow(d);
          let c1 = row.getCell(1);
          let c2 = row.getCell(2);
          let c3 = row.getCell(3);
          c1.alignment = { vertical: 'middle', horizontal: 'center' };
          c2.alignment = { vertical: 'middle', horizontal: 'center' };
          c3.alignment = { vertical: 'middle', horizontal: 'center' };
        });

        worksheetTables.addRow([]);
      }

      const headerGoals = ['', 'Goals', ''];
      let headerRowGoals = worksheetTables.addRow(headerGoals);
      headerRowGoals.eachCell((cell, numb) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF00' },
          bgColor: { argb: 'FF0000FF' }
        };
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
      });
      const dataGoals = [
        [this.gameSummary.control.nameHomeTeam, '', this.gameSummary.control.nameAwayTeam]
      ];

      const goalsDetails = this.gameSummary.control.goalsDetails;
      goalsDetails.forEach(g => {
        let temp = [];
        // {team: 1, time: "00:19", passes: 6}
        // {{gd.time}}' ({{gd.passes}} pass buildup)<
        if (g.team === 1) {
          temp = [g.time + '\'' + ' (' + g.passes +  ' pass buildup)', '', ''];
        } else {
          temp = ['', '', g.time + '\'' + ' (' + g.passes +  ' pass buildup)'];
        }

        dataGoals.push(temp);
      });

      dataGoals.forEach(d => {
        let row = worksheetTables.addRow(d);
        let c1 = row.getCell(1);
        let c2 = row.getCell(2);
        let c3 = row.getCell(3);
        c1.alignment = { vertical: 'middle', horizontal: 'center' };
        c2.alignment = { vertical: 'middle', horizontal: 'center' };
        c3.alignment = { vertical: 'middle', horizontal: 'center' };
      });

      worksheetTables.addRow([]);



    // Footer Row
      let footerRow = worksheetTables.addRow(['This is a gamestats.fi generated excel sheet.']);
      footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFCCFFE5' }
    };
      footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

      worksheetTables.getColumn(1).width = 30;
      worksheetTables.getColumn(2).width = 30;
      worksheetTables.getColumn(3).width = 30;
      worksheetTables.getCell('A6').value = {
      text: 'https://gamestats.fi/',
      hyperlink: 'https://gamestats.fi/'
    };

    // Merge Cells
      worksheetTables.mergeCells(`A${footerRow.number}:C${footerRow.number}`);

    /**********************************************************************************************/
    /* Create Second tab called 'Tables' which is a representation of the results in table format */
    /**********************************************************************************************/

      let worksheetData = workbook.addWorksheet('Data');

    // Generate Excel File with given name
      workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, this.gameSaveName + '.xlsx');
    });






  }

  emailGame() {
   /* First check that all email addresses are valid */
    const bottomSheetRef = this.bottomSheet.open(SendEmailDialogComponent, {
      disableClose: true,
      data: {option: 0, addressList: this.gameEmailAddresses}
      });

    bottomSheetRef.afterDismissed().subscribe(result => {
      console.log(result);
      /*if (result.option === 1) { this.saveGamePDF(); }
      if (result.option === 2) { this.saveGameVLSX(); }*/
    });
  }

  emailGameConfirm() {}

}
