import { GameService } from './../game.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.css']
})
export class LogComponent implements OnInit {
  @Input() isTest: boolean;

  constructor(public gameService: GameService) { }

  ngOnInit() {
  }

}
