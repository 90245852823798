import { RoutingModule } from './routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { DatePipe } from '@angular/common';

import { AppComponent } from './app.component';
import { HttpClientModule} from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NavigationComponent } from './navigation/navigation.component';
import { HomeComponent } from './home/home.component';
import { SetupComponent } from './setup/setup.component';
import { SummaryComponent } from './summary/summary.component';
import { SaveFormatDialogComponent } from './summary/save-format-dialog/save-format-dialog.component';
import { SendEmailDialogComponent } from './summary/send-email-dialog/send-email-dialog.component';
import { CountdownModule } from 'ngx-countdown';
import { LogComponent } from './log/log.component';
import { HelpComponent } from './help/help.component';
import { TermsComponent } from './terms/terms.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule} from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDividerModule } from '@angular/material/divider';


@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    HomeComponent,
    SetupComponent,
    SummaryComponent,
    LogComponent,
    HelpComponent,
    SaveFormatDialogComponent,
    SendEmailDialogComponent,
    TermsComponent
  ],
  imports: [
    RoutingModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    FontAwesomeModule,
    CountdownModule,
    BrowserAnimationsModule,
    // MatSliderModule,
    LayoutModule,
    MatToolbarModule,
    // MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatChipsModule,
    MatTableModule,
    // MatSortModule,
    // MatDialogModule,
    MatBottomSheetModule,
    MatDividerModule
  ],
  providers: [CookieService, DatePipe],
  bootstrap: [AppComponent],
  entryComponents: [SaveFormatDialogComponent, SendEmailDialogComponent]
})
export class AppModule {}
